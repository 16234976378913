import {
  Box,
  Divider,
  Flex,
  Heading,
  Image,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import "../assets/css/AboutUs.css";
import bgImage from "../assets/images/BGImage/skp2.png";
import skp from "../assets/images/SKP Full logo2.png";
import commitmentLogo from "../assets/images/commitment.png";
import creativityLogo from "../assets/images/creativity.png";
import ethicsLogo from "../assets/images/ethics.png";
import hemant from "../assets/images/hemant.jpeg";
import qualityLogo from "../assets/images/quality.png"; // Replace with your actual logo paths
import skpNew from "../assets/images/SKP_new.png";

const AboutPage = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        } else {
          entry.target.classList.remove("show");
        }
      });
    });

    const hiddenElements = document.querySelectorAll(".hidden");
    hiddenElements.forEach((el) => observer.observe(el));

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scale = 1 + scrollY * 0.0009;

  const imageStyle = {
    transform: `scale(${scale})`,
    transition: "transform 0.01s ease-in-out",
  };
  return (
    <Box w="99.13vw" overflowX="hidden" minHeight="calc(100vh - 72px)">
      <Box
        bgImage={bgImage}
        bgSize="cover"
        bgPosition="center"
        h="24vh"
        position="relative"
        style={imageStyle}
      >
        <Box
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          textAlign="center"
        >
          <Heading
            as="h1"
            size={{ base: "xl", md: "3xl" }}
            textShadow="1px 1px 2px rgba(0, 0, 0, 0.3)"
            fontWeight="bold"
            color="White"
          >
            About Us
          </Heading>
        </Box>
      </Box>
      <Flex
        style={{ fontFamily: "Montserrat" }}
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        minHeight="calc(100vh - 72px)"
        bgGradient={"linear(to-b,white, #c8e4e9)"}
        color="#333"
      >
        <Flex
          flexDir={{ base: "column", md: "row" }}
          justifyContent="space-evenly"
          alignItems="center"
          w="100%"
          p={8}
          mt={4}
        >
          <Box
            flex={1}
            p={{ base: 4, md: 8 }}
            pb={{ base: 2, md: 8 }}
            maxW={{ base: "90%", md: "50%" }}
            textAlign="left"
            bgColor="white"
            borderRadius="lg"
            boxShadow="lg"
            className="slide-in"
          >
            <div className="heading">
              <Heading
                style={{ fontFamily: "Montserrat" }}
                as="h2"
                size={{ base: "lg", md: "xl" }}
                fontWeight="bold"
                color="#33d6ff"
                mt={4}
                mb={2}
                textAlign="center"
              >
                Brand Story
              </Heading>
            </div>
            <Divider mt="2" />
            <Text
              textAlign="center"
              fontWeight="bold"
              lineHeight="2"
              color="#333"
              fontSize={{ base: "md", md: "lg" }}
              mt={4}
            >
              Manufacturing & Wholesale Distribution of Diamond Studded
              Jewellery, across India
              <br />
              Established in 2007
              <br />
              17 Glorious Years of Sustainable Growth
            </Text>
            <div className="heading">
              <Heading
                style={{ fontFamily: "Montserrat" }}
                as="h2"
                size={{ base: "lg", md: "xl" }}
                fontWeight="bold"
                color="#33d6ff"
                mt={12}
                mb={2}
                textAlign="center"
              >
                Distinctive Features of Sankalp Diamond
              </Heading>
            </div>
            <Divider mt="2" />
            <Text
              textAlign="center"
              textColor="#000d1a"
              fontWeight="bold"
              lineHeight="2"
              // listStyleType=""
              mx="auto"
              fontSize={{ base: "md", md: "lg" }}
              mt={2}
            >
              Universe of Light Weight Diamond Studded Jewellery
              <br />
              Daily Wearable & Daily Saleable Ready Jewellery
              <br />
              Cost Optimization & Cost Efficiency
              <br />
              Faster Turn Around Time
            </Text>
          </Box>

          <Box
            flex={{ base: 1, md: 1 }}
            maxW={{ base: "90%", md: "40%" }}
            overflow="hidden"
            display={{ base: "none", md: "block" }}
            className="reveal-from-top"
            bgColor="white"
            borderRadius="full"
            boxShadow="lg"
          >
            <Image
              src={skpNew}
              p={12}
              bgColor="white"
              alt="Your Image Alt Text"
              width="100%"
              objectFit="cover"
              boxShadow="lg"
            />
          </Box>
        </Flex>
        <Flex
          mt={16}
          flexDir={{ base: "column", md: "row" }}
          justifyContent="space-evenly"
          w="100%"
          p={8}
          bgColor="#006078"
          className="slide-in"
        >
          <Heading
            style={{ fontFamily: "Montserrat" }}
            as="h1"
            size={{ base: "lg", md: "2xl" }}
            fontWeight="bold"
            color="white"
            mt={12}
            mb={2}
            textAlign="center"
          >
            Core Values
          </Heading>
          <Flex
            w="60%"
            flexDir={{ base: "column", md: "row" }}
            justifyContent="center"
            alignItems="center"
            ml={16}
          >
            <div className="logo hidden">
              <CircularComponent img={qualityLogo} title="Quality" />
            </div>

            <div className="logo hidden">
              <CircularComponent img={commitmentLogo} title="Commitment" />
            </div>

            <div className="logo hidden">
              <CircularComponent img={creativityLogo} title="Creativity" />
            </div>

            <div className="logo hidden">
              <CircularComponent img={ethicsLogo} title="Ethics" />
            </div>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        style={{ fontFamily: "Montserrat" }}
        flexDir="column"
        justifyContent="center"
        alignItems="center"
        className="slide-in"
      >
        <Flex
          bgColor="white"
          flexDir={{ base: "column", md: "row" }}
          justifyContent="space-evenly"
          bgGradient={"linear(to-b,white, #c8e4e9)"}
          alignItems="center"
          w="100%"
          p={8}
        >
          <Box
            flex={{ base: 1, md: 2 }}
            maxW={{ base: "90%", md: "20%" }}
            textAlign="left"
            bgColor="rgba(255, 255, 255, 0.9)"
            borderRadius="lg"
            boxShadow="lg"
            display={{ base: "none", md: "block" }}
            position="relative"
            className="hidden"
          >
            <Image
              src={hemant}
              alt="Your Image Alt Text"
              maxH="600px"
              width="100%"
              objectFit="cover"
              borderRadius="lg"
              boxShadow="lg"
            />
            <Box
              bgColor="rgba(0, 0, 0, 0.7)"
              p={2}
              borderBottomRadius="lg"
              position="absolute"
              bottom="0"
              left="0"
              right="0"
              textAlign="center"
            >
              <Text color="white" fontWeight="bold" fontSize="24px">
                {" "}
                Hemant Rathod
              </Text>
            </Box>
          </Box>
          <Box
            flex={1}
            p={{ base: 4, md: 8 }}
            pb={{ base: 2, md: 8 }}
            maxW={{ base: "90%", md: "45%" }}
            textAlign="left"
            bgColor="rgba(255, 255, 255, 0.9)"
            borderRadius="lg"
            boxShadow="lg"
            className="hidden"
          >
            <div className="heading">
              <Heading
                style={{ fontFamily: "Montserrat" }}
                as="h2"
                size={{ base: "lg", md: "xl" }}
                fontWeight="bold"
                color="#33d6ff"
                mt={4}
                mb={2}
                textAlign="center"
              >
                Mr. Hemant Rathod <br />
                Managing Partner | Founder of Sankalp Diamond
              </Heading>
            </div>
            <Divider mt="2" />

            <UnorderedList
              lineHeight="2"
              listStyleType="disc"
              ml={{ base: 6, md: 8 }}
              fontSize={{ base: "md", md: "lg" }}
              mt={2}
            >
              <ListItem
                fontWeight="bold"
                color="#333"
                fontSize={{ base: "md", md: "lg" }}
              >
                Visionary First Generation Jewellery Entrepreneur
              </ListItem>
              <ListItem
                fontWeight="bold"
                color="#333"
                fontSize={{ base: "md", md: "lg" }}
              >
                Innovator | Trendsetter | Luxury Craftsmanship Leader | Creative
                Force behind Sankalp | Inspiration to Many
              </ListItem>
              <ListItem
                fontWeight="bold"
                color="#333"
                fontSize={{ base: "md", md: "lg" }}
              >
                17 years of hands on experience in creating a dedicated &
                determined high performing Team
              </ListItem>
              <ListItem
                fontWeight="bold"
                color="#333"
                fontSize={{ base: "md", md: "lg" }}
              >
                Loves & Passionate about exquisite Jewel creativity that blends
                artistry and craftsmanship for those who cherish elegance and
                luxury.
              </ListItem>
              <ListItem
                fontWeight="bold"
                color="#333"
                fontSize={{ base: "md", md: "lg" }}
              >
                Connects all stakeholders in the Process of Fulfillment of
                Committed Promise – A Sankalp
              </ListItem>
            </UnorderedList>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

const CircularComponent = ({ img, title }) => {
  const circleStyle = {
    width: "160px",
    height: "160px",
    borderRadius: "50%",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    margin: "1rem",
    overflow: "hidden",
  };
  return (
    <Box style={circleStyle} className="logo hidden">
      <Flex direction="column" alignItems="center">
        <Image src={img} alt={title} maxW="50px" />
        <Text
          mx={4}
          fontSize={{
            base: "sm",
            md: "lg",
          }}
          fontWeight="bold"
          mt={{
            base: "0.25rem",
            md: "1rem",
          }}
        >
          {title}
        </Text>
      </Flex>
    </Box>
  );
};

export default AboutPage;
