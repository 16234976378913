import {
  Box,
  Flex,
  Heading,
  Image,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import "../assets/css/WhyUs.css";
import service from "../assets/images/service.jpg";
import why from "../assets/images/why.jpg";

const WhyUsPage = () => {
  const [, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        } else {
          entry.target.classList.remove("show");
        }
      });
    });

    const hiddenElements = document.querySelectorAll(".hidden");
    hiddenElements.forEach((el) => observer.observe(el));

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Flex
        flexDir="row"
        justifyContent="space-evenly"
        alignItems="center"
        minHeight="calc(100vh - 72px)"
        bgGradient="linear(-50deg, white 50%, #3bd6d3 50%)"
        bgPosition="center"
        color="#333"
        fontFamily="Montserrat"
        p={8}
      >
        <span>
          <Box
            flex={1}
            p={8}
            mr={4}
            bgGradient="linear(to-l,  #f2f2f2 , white )"
            borderRadius="lg"
            boxShadow="lg"
            className="slide-in"
          >
            <Heading as="h1" size="3xl" fontWeight="bold" mb="2rem">
              Why Us?
            </Heading>
            <Text fontSize="lg" mb="1rem">
              <b>Product Offering</b> – Larger collection of lightweight
              Products | Daily Wearable, thus Daily Saleable Products | Any time
              Ready Jewellery (2000 - 2500 Carats)
            </Text>
            <Text fontSize="lg" mb="1rem">
              <b> Authenticity</b> – Consistency & Genuineness of Natural
              Diamonds (4Cs), Metal Finesse
            </Text>
            <Text fontSize="lg" mb="1rem">
              <b> Services</b> – Razor focused attention as vendor partner,
              on-time supply, faster replenishment
            </Text>
            <Text fontSize="lg" mb="1rem">
              <b> Infrastructure</b> – India's Leading Jewellery Mfg. with
              advanced technology providing export finish craftsmanship
            </Text>
            <Text fontSize="lg" mb="1rem">
              <b> Value for Money</b> – Dedicated Team for Faster & Effective
              Services
            </Text>
            <Text fontSize="lg" mb="1rem">
              <b> Creating win - win situation</b> – Transparent, Fair & Ethical
              deals, long term relationship approach
            </Text>
            <Text fontSize="lg" mb="1rem">
              <b> Regional Designing & PD</b> - Focus on making the right
              product for the right customer, across demographics
            </Text>
            <Text fontSize="lg" mb="1rem">
              <b> Wide distribution network</b> – Serving Large Format Family
              Jewelers & Corporate Chain Stores
            </Text>
          </Box>
        </span>

        <Box
          flex={1}
          maxW={{ base: "90%", md: "30%" }}
          textAlign="left"
          overflow="hidden"
          display={{ base: "none", md: "block" }}
          className="reveal-from-top"
        >
          <Image
            src={why}
            alt="Your Image Alt Text"
            objectFit="cover"
            w="100%"
            maxH="600px"
          />
        </Box>
      </Flex>
      <Flex
        flexDir="row"
        justifyContent="space-evenly"
        alignItems="center"
        minHeight="calc(100vh - 72px)"
        bgGradient="linear(50deg, white 50%, #3bd6d3 50%)"
        bgPosition="center"
        color="#333"
        fontFamily="Montserrat"
        p={8}
      >
        <Box
          flex={1}
          maxW={{ base: "90%", md: "30%" }}
          textAlign="left"
          overflow="hidden"
          display={{ base: "none", md: "block" }}
          className="reveal-from-top"
        >
          <Image
            src={service}
            alt="Your Image Alt Text"
            objectFit="cover"
            w="100%"
            maxH="600px"
          />
        </Box>

        <span>
          <Box
            flex={1}
            p={8}
            mr={4}
            bgGradient="linear(to-r,  #f2f2f2 , white )"
            borderRadius="lg"
            boxShadow="lg"
            className="hidden"
          >
            <Heading as="h1" size="3xl" fontWeight="bold" mb="2rem">
              Our Service
            </Heading>
            <UnorderedList
              lineHeight="2"
              listStyleType="disc"
              ml={{ base: 6, md: 8 }}
              fontSize={{ base: "md", md: "lg" }}
              mt={2}
            >
              <ListItem
                fontWeight="bold"
                color="#333"
                fontSize={{ base: "md", md: "lg" }}
              >
                Always offer value for money
              </ListItem>
              <ListItem
                fontWeight="bold"
                color="#333"
                fontSize={{ base: "md", md: "lg" }}
              >
                Transparency at the core - Deal fairly & ethically
              </ListItem>
              <ListItem
                fontWeight="bold"
                color="#333"
                fontSize={{ base: "md", md: "lg" }}
              >
                Vendor Partner - Long Standing Business Relationship
              </ListItem>
              <ListItem
                fontWeight="bold"
                color="#333"
                fontSize={{ base: "md", md: "lg" }}
              >
                Add value to the lives & business we serve
              </ListItem>
              <ListItem
                fontWeight="bold"
                color="#333"
                fontSize={{ base: "md", md: "lg" }}
              >
                Our primary aim at all times is to provide customer delight
              </ListItem>
              <ListItem
                fontWeight="bold"
                color="#333"
                fontSize={{ base: "md", md: "lg" }}
              >
                It is this service, perfected over 17 Years, that has helped
                Sankalp Diamond become a trusted & sought after name
              </ListItem>
              <ListItem
                fontWeight="bold"
                color="#333"
                fontSize={{ base: "md", md: "lg" }}
              >
                All our efforts are towards satisfying our customers, for that
                is the reason we exist & growing in the process…
              </ListItem>
            </UnorderedList>
          </Box>
        </span>
      </Flex>
    </>
  );
};

export default WhyUsPage;
