import { Box, Flex, Heading } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import optimumCapacity from "../assets/images/Infra/Optimum.jpg";
import pd from "../assets/images/Infra/Pd Team.jpg";
import workforce from "../assets/images/Infra/Workforce.jpg";
import avg from "../assets/images/Infra/avg4.jpg";
import InfraCard from "../components/InfraCard";

import bg4 from "../assets/images/BGImage/bg1.jpeg";
import igiTeam from "../assets/images/Infra/IGI team.jpg";
import designer from "../assets/images/Infra/designer.png";
import igiCertificate from "../assets/images/Infra/igi.jpg";
import office1 from "../assets/images/Infra/office1.jpeg";
import office2 from "../assets/images/Infra/office2.jpeg";
import office3 from "../assets/images/Infra/office3.jpeg";
import office4 from "../assets/images/Infra/office4.jpeg";
import office5 from "../assets/images/Infra/office5.jpeg";
const InfrastructurePage = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scale = 1 + scrollY * 0.0009; // Adjust the multiplier for desired zoom intensity

  const imageStyle = {
    transform: `scale(${scale})`,
    transition: "transform 0.01s ease-in-out",
  };
  return (
    <Box maxW="100vw" overflowX="hidden">
      <Box
        bgImage={bg4}
        bgSize="cover"
        bgPosition="center"
        h="24vh"
        position="relative"
        style={imageStyle}
      >
        <Box
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          textAlign="center"
        >
          <Heading as="h1" size="2xl" color="white">
            Infrastructure
          </Heading>
        </Box>
      </Box>
      {/* Main Container */}
      <Flex
        flexDirection={{ base: "column", md: "row" }}
        alignItems="center"
        justifyContent="center"
        minHeight="calc(100vh - 72px)"
        bgGradient="linear(to left, #f5f5f5, #3bd6d3)"
        // bgImage={jewel}
        bgSize="cover"
        bgPosition="center"
        // bgColor="whiteAlpha.100"
        color="#333"
        fontFamily="Montserrat"
        p={8}
      >
        <Flex
          className="slide-in"
          mt={4}
          w="80vw"
          minH="80vh"
          // width="90%"
          // bgGradient="linear(to-r, #d8ebef, #ffd179, #ffe0c1)"
          // bg="gray.100"
          bg="rgba(255, 255, 255, 0.9)"
          borderRadius="lg"
          // boxShadow="lg"
          p={{ base: 1.5, md: 8 }}
          marginBottom={4}
          flexDirection={{ base: "column", md: "row" }}
          alignItems="center"
          justifyContent="space-evenly"
          // bgColor="transparent"
          flexWrap="wrap"
        >
          <InfraCard
            imageUrls={[workforce, pd]}
            title={`Manufacturing`}
            // description={`Manufacturing`}
            flexBasis={{ base: "100%", md: "calc(33.3333% - 16px)" }}
            // style={{ margin: "8px" }}
          />
          <InfraCard
            imageUrls={[optimumCapacity]}
            title={`Quality Assurance`}
            // description={`Quality Assurance`}
            flexBasis={{ base: "100%", md: "calc(33.3333% - 16px)" }}
            style={{ margin: "8px" }}
          />
          {/* <InfraCard
            imageUrls={[utilizedCapacity]}
            title={`Utilized Capacity`}
            // description={`120 kg per year in house`}
            flexBasis={{ base: "100%", md: "calc(33.3333% - 16px)" }}
          />
          <InfraCard
            imageUrls={[currProd]}
            title={`Current Production`}
            // description={`100 Pcs per day`}
            flexBasis={{ base: "100%", md: "calc(33.3333% - 16px)" }}
            // style={{ margin: "8px" }}
          /> */}

          <InfraCard
            imageUrls={[avg]}
            title={`Avg 4 Grams per product `}
            description={`Avg 0.40 Carats per product`}
            flexBasis={{ base: "100%", md: "calc(33.3333% - 16px)" }}
            style={{ margin: "8px" }}
          />
          <InfraCard
            imageUrls={[igiTeam, igiCertificate]}
            title={`IGI Lab`}
            description={`Three times a week`}
            flexBasis={{ base: "100%", md: "calc(33.3333% - 16px)" }}
            style={{ margin: "8px" }}
          />
          <InfraCard
            imageUrls={[designer]}
            title={`Experienced Designing, PD Team `}
            description={`New Product Development`}
            flexBasis={{ base: "100%", md: "calc(33.3333% - 16px)" }}
          />
          <InfraCard
            imageUrls={[office3, office1, office5, office2, office4]}
            title={`Our New Corporate Office`}
            description={`   `}
            flexBasis={{ base: "100%", md: "calc(33.3333% - 16px)" }}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default InfrastructurePage;
