import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import bracelet1 from "../assets/images/Products/Gallery/bracelet 1.jpeg";
import bracelet2 from "../assets/images/Products/Gallery/bracelet2.jpg";
import designer1 from "../assets/images/Products/Gallery/designer1.jpg";
import designer2 from "../assets/images/Products/Gallery/designer2.jpg";
import designer3 from "../assets/images/Products/Gallery/designer3.jpg";
import designer4 from "../assets/images/Products/Gallery/designer4.jpg";
import earring1 from "../assets/images/Products/Gallery/earring1.jpg";
import earring2 from "../assets/images/Products/Gallery/earring2.jpg";
import gentsring1 from "../assets/images/Products/Gallery/gentsring1.jpg";
import gentsring2 from "../assets/images/Products/Gallery/gentsring2.jpg";
import necklace1 from "../assets/images/Products/Gallery/necklace1.jpg";
import necklace2 from "../assets/images/Products/Gallery/necklace2.jpg";
import pendant1 from "../assets/images/Products/Gallery/pendant1.jpg";
import pendant2 from "../assets/images/Products/Gallery/pendant2.jpg";
import ring1 from "../assets/images/Products/Gallery/ring1.jpg";
import ring2 from "../assets/images/Products/Gallery/ring2.jpg";
import tanmaniya1 from "../assets/images/Products/Gallery/tanmaniya1.jpg";
import tanmaniya2 from "../assets/images/Products/Gallery/tanmaniya2.jpg";
import bangle1 from "../assets/images/Products/Gallery/bangle 1.jpeg";
import bangle2 from "../assets/images/Products/Gallery/bangle 2 .jpg";
import Sidebar from "../components/Sidebar";

const ProductGallery = () => {
  const ringsSectionRef = useRef(null);
  const earringsSectionRef = useRef(null);
  const pendantSectionRef = useRef(null);
  const braceletSectionRef = useRef(null);
  const banglesSectionRef = useRef(null);
  const tanmaniyaSectionRef = useRef(null);
  const necklaceSectionRef = useRef(null);
  const gentRingSectionRef = useRef(null);
  const designerSectionRef = useRef(null);
  const [, setScrollY] = useState(0);

  const location = useLocation();
  const receivedData = location.state;

  const scrollToSection = (sectionRef) => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };
  useEffect(() => {
    const section = document.getElementById(receivedData);
    if (section) {
      setTimeout(() => {
        section.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 100);
    }
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        } else {
          entry.target.classList.remove("show");
        }
      });
    });

    const hiddenElements = document.querySelectorAll(".hidden");
    hiddenElements.forEach((el) => observer.observe(el));

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [receivedData]);

  return (
    <Box display="flex" bgColor="#f2f2f2">
      <Box zIndex="0">
        <Sidebar
          scrollToSection={scrollToSection}
          ringsSectionRef={ringsSectionRef}
          earringsSectionRef={earringsSectionRef}
          pendantSectionRef={pendantSectionRef}
          braceletSectionRef={braceletSectionRef}
          banglesSectionRef={banglesSectionRef}
          tanmaniyaSectionRef={tanmaniyaSectionRef}
          necklaceSectionRef={necklaceSectionRef}
          gentRingSectionRef={gentRingSectionRef}
          designerSectionRef={designerSectionRef}
        />
      </Box>

      {/* Main content */}
      <Box w="100%" p={4} mx="auto">
        <div ref={ringsSectionRef} id="rings-section">
          <Category title="Rings" images={[ring1, ring2]} />
        </div>
        <div ref={earringsSectionRef} id="earrings-section">
          <Category title="Earrings" images={[earring1, earring2]} />
        </div>
        <div ref={pendantSectionRef} id="pendant-section">
          <Category title="Pendant Sets" images={[pendant1, pendant2]} />
        </div>
        <div ref={braceletSectionRef} id="bracelet-section">
          <Category title="Bracelets" images={[bracelet1, bracelet2]} />
        </div>
        <div ref={banglesSectionRef} id="bangles-section">
          <Category title="Bangles" images={[bangle1, bangle2]} />
        </div>
        <div ref={tanmaniyaSectionRef} id="tanmaniya-section">
          <Category title="Tanmaniya" images={[tanmaniya1, tanmaniya2]} />
        </div>
        <div ref={necklaceSectionRef} id="necklace-section">
          <Category title="Necklace Sets" images={[necklace1, necklace2]} />
        </div>
        <div ref={gentRingSectionRef} id="gentRing-section">
          <Category title="Gent's Rings" images={[gentsring1, gentsring2]} />
        </div>
        <div ref={designerSectionRef} id="designer-section">
          <Category
            title="Designer Products"
            images={[designer1, designer2, designer3, designer4]}
          />
        </div>
      </Box>
    </Box>
  );
};

const Category = ({ title, images }) => {
  return (
    <Box
      width={{ base: "100%", md: "calc(60% - 2rem)" }}
      margin="1rem"
      ml={{ base: "0", md: "24" }}
      p="1rem"
      bgGradient="linear(to top, #99ecff , #ffffff 100%)"
      borderRadius="lg"
      boxShadow="lg"
      overflow="hidden"
      className="slide-in"
    >
      <Flex
        width="100%"
        height={{ base: "60px", md: "90px" }}
        background="#44bcd8"
        justifyContent="center"
        borderTopRadius="lg"
        boxShadow="md"
        pt={5}
        alignItems="center"
      >
        <Text
          fontSize={{ base: "2xl", md: "3xl" }}
          fontWeight="bold"
          marginBottom="1rem"
          textAlign="center"
          textColor="white"
        >
          {title}
        </Text>
      </Flex>
      <Flex flexWrap="wrap" justifyContent="space-between" p="1rem">
        {images.map((image, index) => (
          <Image
            key={index}
            src={image}
            alt={`Category ${title} Image ${index + 1}`}
            maxW={{ base: "100%", md: "45%" }}
            height="auto"
            borderRadius="lg"
            boxShadow="lg"
            borderColor="#99eaff"
            mt={4}
          />
        ))}
      </Flex>
    </Box>
  );
};

export default ProductGallery;
