import React from "react";
import { Box, Heading, Text, Flex } from "@chakra-ui/react";
import "../assets/css/HorizontalCard.css";

const HorizontalCard = ({ title, description, imageUrl }) => {
  return (
    <Flex
      direction={{ base: "column", md: "row" }}
      w="100%"
      borderRadius={{ base: "lg", md: "full" }} // Make the borderRadius the same for both base and md
      bgGradient="linear(to-r, white, #f2f2f2)"
      p={4} // Reduce padding to make the card smaller
      color="white"
      minH={{ base: "60px", md: "120px" }}
      mt={4} // Reduce top margin
      boxShadow="lg"
      className="slide-in fixW"
      alignItems={{ base: "center", md: "stretch" }}
    >
      {/* Small Box */}
      <Flex
        w={{ base: "80%", md: "30%" }}
        bgGradient="linear(135deg, white, #004d4d)"
        minH={{ base: "90px" }}
        px={4} // Reduce padding
        py={1}
        borderRadius={{ base: "lg", md: "full" }} // Make the borderRadius the same for both base and md
        alignItems="center"
        justifyContent="center"
        flexShrink={0}
        boxShadow={{ base: "lg", md: "none" }}
        textAlign="center"
      >
        <Heading
          as="h3"
          fontFamily="Montserrat"
          size={{ base: "sm", md: "sm" }}
        >
          {title}
        </Heading>
      </Flex>
      <Box flex="1" ml={{ base: 4, md: 6 }} mt={{ base: 2, md: 0 }}>
        <Text color="black" lineHeight="1.5">
          {" "}
          {/* Adjusted line height */}
          {description}
        </Text>
      </Box>
    </Flex>
  );
};

export default HorizontalCard;
