import { Flex, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
const IconComponent = ({ FaIconName, iconTitle, hoverColor, functionCall }) => {
  return (
    <Icon
      w={{ base: "30px", md: "40px" }}
      h={{ base: "30px", md: "40px" }}
      mx={4}
      as={FaIconName}
      aria-label={iconTitle}
      fontSize="lg"
      color="white"
      bg="transparent"
      cursor="pointer"
      _hover={{ color: hoverColor }}
      onClick={functionCall}
    />
  );
};

console.warn = () => {};

const Footer = () => {
  console.warn = () => {};
  const openInstagram = () => {
    const instagramLink = `https://www.instagram.com/sankalpdiamond28/`;
    window.open(instagramLink, "_blank");
  };
  const openFacebook = () => {
    const facebookLink = `https://www.facebook.com/sankalpdiamond28/`;
    window.open(facebookLink, "_blank");
  };
  const openLinkedin = () => {
    const linkedinLink = `https://www.linkedin.com/company/sankalp-diamond/`;
    window.open(linkedinLink, "_blank");
  };

  return (
    <Flex
      bg="gray.800"
      color="white"
      py={4}
      justifyContent="center"
      alignItems="center"
    >
      <Text mr={4} fontSize={{ base: "l", md: "2xl" }} fontWeight="bold">
        Follow Us:
      </Text>

      <IconComponent
        FaIconName={FaInstagram}
        iconTitle="Instagram"
        hoverColor="pink.400"
        functionCall={openInstagram}
      />

      <IconComponent
        FaIconName={FaFacebook}
        iconTitle="Facebook"
        hoverColor="blue.600"
        functionCall={openFacebook}
      />

      <IconComponent
        FaIconName={FaLinkedin}
        iconTitle="LinkedIn"
        hoverColor="#0072b1"
        functionCall={openLinkedin}
      />
    </Flex>
  );
};

export default Footer;
