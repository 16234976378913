import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Flex, Heading, Image, useMediaQuery } from "@chakra-ui/react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../assets/css/Events.css";
import eventImage from "../assets/images/Events/EventImage.jpg";
import backgroundImage from "../assets/images/Events/backgroundImage.png";
import video1 from "../assets/images/Events/video1.mp4";
import video2 from "../assets/images/Events/video2.mp4";
import video3 from "../assets/images/Events/video3.mp4";
import video4 from "../assets/images/Events/video4.mp4";

const Events = () => {
  const [scrollY, setScrollY] = useState(0);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [currentSlide, setCurrentSlide] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scale = 1 + scrollY * 0.0009;

  const imageStyle = {
    transform: `scale(${scale})`,
    transition: "transform 0.01s ease-in-out",
  };
  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current) => {
      setCurrentSlide(current);
    },
  };

  return (
    <Box maxW="100vw" overflowX="hidden">
      <Box
        bgImage={backgroundImage}
        bgSize="cover"
        bgPosition="center"
        h="20vh"
        position="relative"
        style={imageStyle}
      >
        <Box
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          textAlign="center"
        >
          <Heading as="h1" size="2xl" color="blackAlpha.800">
            Events
          </Heading>
        </Box>
      </Box>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="calc(100vh - 72px)"
        bgGradient="linear(to left, #f5f5f5, #3bd6d3)"
        p={8}
      >
        <Box
          bgColor="blackAlpha.700"
          mt={4}
          width="90%"
          maxH={isMobile ? "900px" : "1000px"}
          borderRadius="md"
          boxShadow="lg"
        >
          {!isMobile ? (
            <Slider {...sliderSettings}>
              <div>
                <Image
                  w="100%"
                  objectFit="contain"
                  src={eventImage}
                  alt="Landscape Image"
                  maxH="900px"
                />
              </div>

              <div>
                <Flex
                  flexDir={{ base: "column", md: "row" }}
                  alignItems="center"
                >
                  <video
                    src={video1}
                    controls
                    width="33.33%"
                    style={{ maxHeight: "900px" }}
                    muted
                    autoPlay
                  />
                  <video
                    src={video2}
                    controls
                    width="33.33%"
                    style={{ maxHeight: "900px" }}
                    muted
                  />
                  <video
                    src={video3}
                    controls
                    width="33.33%"
                    style={{ height: "900px" }}
                    muted
                  />
                </Flex>
              </div>

              <div>
                <video
                  src={video4}
                  controls
                  muted
                  width="100%"
                  style={{ maxHeight: "900px" }}
                  autoplay
                />
              </div>
            </Slider>
          ) : (
            <Slider {...sliderSettings}>
              <div>
                <Image
                  w="100%"
                  objectFit="contain"
                  src={eventImage}
                  alt="Landscape Image"
                  minH="700px"
                />
              </div>
              <div>
                <video
                  src={video1}
                  controls
                  width="100%"
                  style={{ minHeight: "700px" }}
                  muted
                  autoPlay
                />
              </div>
              <div>
                <video
                  src={video2}
                  controls
                  width="100%"
                  style={{ minHeight: "700px" }}
                  muted
                  autoPlay
                />
              </div>
              <div>
                <video
                  src={video3}
                  controls
                  width="100%"
                  style={{ minHeight: "700px" }}
                  muted
                  autoPlay
                />
              </div>
              <div>
                <video
                  src={video4}
                  controls
                  width="100%"
                  style={{ minHeight: "700px" }}
                  muted
                  autoPlay
                />
              </div>
            </Slider>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default Events;
