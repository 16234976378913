import {
  Box,
  Button,
  Center,
  Flex,
  Image,
  Text,
  Heading,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import bis from "../assets/images/Home/BIS.webp";
import replenishment from "../assets/images/Home/Replenishment.png";
import gjc from "../assets/images/Home/gjc.png";
import gjepc from "../assets/images/Home/gjepc.svg";
import igi from "../assets/images/Home/igi.svg";
import ontIme from "../assets/images/Home/onTime.png";
import quality from "../assets/images/Home/quality.png";
import sourced from "../assets/images/Home/sourced.png";
import vendor from "../assets/images/Home/vendor.png";
import prod1 from "../assets/images/Prod1.png";
import prod3 from "../assets/images/Prod3.png";
import prod4 from "../assets/images/Prod4.png";
import category3Image1 from "../assets/images/Products/Colors Of Life 1.jpg";
import category3Image2 from "../assets/images/Products/Colors Of Life 2.jpg";
import category1Image1 from "../assets/images/Products/Contempory 1.jpg";
import category1Image2 from "../assets/images/Products/Contempory 2.jpg";
import category2Image1 from "../assets/images/Products/Forever Classics 1.jpg";
import category2Image2 from "../assets/images/Products/Forever Classics 2.jpg";
import category6Image1 from "../assets/images/Products/celeb 1.jpg";
import category6Image2 from "../assets/images/Products/celeb 2.jpg";
import category7Image1 from "../assets/images/Products/generics 1.jpg";
import category7Image2 from "../assets/images/Products/generics 2.jpg";
import category8Image1 from "../assets/images/Products/high 1.jpg";
import category8Image2 from "../assets/images/Products/high 2.jpg";
import category4Image1 from "../assets/images/Products/kings 1.jpg";
import category4Image2 from "../assets/images/Products/kings 2.jpg";
import category9Image1 from "../assets/images/Products/value 1.jpg";
import category9Image2 from "../assets/images/Products/value 2.jpg";
import category5Image1 from "../assets/images/Products/work 1.jpg";
import category5Image2 from "../assets/images/Products/work 2.jpg";
import ImageSlider from "../components/ImageSlider.js";

const Home = () => {
  const [, setScrollY] = useState(0);
  const category1Images = [category1Image1, category1Image2];
  const category2Images = [category2Image1, category2Image2];
  const category3Images = [category3Image1, category3Image2];
  const category4Images = [category4Image1, category4Image2];
  const category5Images = [category5Image1, category5Image2];
  const category6Images = [category6Image1, category6Image2];
  const category7Images = [category7Image1, category7Image2];
  const category8Images = [category8Image1, category8Image2];
  const category9Images = [category9Image1, category9Image2];

  useEffect(() => {
    // window.scrollBy(0, 1);
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        } else {
          entry.target.classList.remove("show");
        }
      });
    });

    const hiddenElements = document.querySelectorAll(".hidden");
    hiddenElements.forEach((el) => observer.observe(el));

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // const [scrollY, setScrollY] = useState(0);

  const images = [prod4, prod3, prod1];
  return (
    <Box bgColor="gray.200" minH="100vh">
      <ImageSlider images={images} />
      <Flex
        flexDirection="column"
        alignItems="center"
        minHeight="calc(100vh - 72px)"
        bgColor="#f2f2f2"
        fontFamily="Montserrat"
      >
        <Flex
          flexDir={{ base: "column", md: "row" }}
          justifyContent="space-evenly"
          alignItems="center"
          w="100%"
          p={8}
          bgColor="#006078"
          className="hidden"
        >
          <CircularComponent img={sourced} title="Responsibly Sourced" />
          <CircularComponent img={vendor} title="Vendor Partner" />
          <CircularComponent img={ontIme} title="On Time Supply" />
          <CircularComponent img={replenishment} title="Faster Replenishment" />
          <CircularComponent img={quality} title="Quality Assurance" />
        </Flex>
        <Heading
          as="h1"
          size={{ base: "lg", md: "2xl" }}
          mt="16"
          mb="6"
          color="gray.700"
          fontFamily="Montserrat"
        >
          Jewellery Collection
        </Heading>
        <Flex
          flexDirection={{ base: "column", md: "row" }}
          justifyContent="space-evenly"
          alignItems="center"
          w="100%"
          bgColor="transparent"
          className="hidden"
          flexWrap="wrap"
        >
          <Category title="Contemporary - A New You" images={category1Images} />
          <Category
            title="Forever Classics"
            images={category2Images}
            buttonState="rings-section"
          />
          <Category
            title="Colours Of Life"
            images={category3Images}
            buttonState="designer-section"
          />
          <Category
            title="For The Kings"
            images={category4Images}
            buttonState="gentRing-section"
          />
          <Category
            title="9 to 5 - Work Wear"
            images={category5Images}
            buttonState="bracelet-section"
          />
          <Category
            title="Celebrations"
            images={category6Images}
            buttonState="rings-section"
          />
          <Category
            title="Variations In Generics"
            images={category7Images}
            buttonState="pendant-section"
          />
          <Category
            title="High End"
            images={category8Images}
            buttonState="rings-section"
          />
          <Category
            title="Value Propositions"
            images={category9Images}
            buttonState="tanmaniya-section"
          />
        </Flex>
      </Flex>

      <Flex
        flexDirection="row"
        justifyContent="space-evenly"
        alignItems="center"
        w="100%"
        p={8}
        bgColor="#f2f2f2"
        className="slide-in"
      >
        <Image
          src={igi}
          alt="Logo 1"
          background="white"
          maxW={{ base: "80px", md: "150px" }}
          h={{ base: "80px", md: "150px" }}
        />
        <Image
          src={gjc}
          alt="Logo 2"
          maxW={{ base: "80px", md: "150px" }}
          h={{ base: "80px", md: "150px" }}
        />
        <Image
          src={bis}
          alt="Logo 3"
          maxW={{ base: "80px", md: "150px" }}
          h={{ base: "80px", md: "150px" }}
        />
        <Image
          src={gjepc}
          alt="Logo 4"
          background="white"
          maxW={{ base: "80px", md: "150px" }}
          h={{ base: "80px", md: "150px" }}
          p={2}
        />
      </Flex>
    </Box>
  );
};

const Category = ({ title, images, buttonState }) => {
  return (
    <Box
      width={{ base: "80%", md: "calc(60% - 2rem)" }}
      margin="1rem"
      p="1rem"
      bgGradient="linear(to top, #99ecff , #ffffff 100%)"
      borderRadius="lg"
      boxShadow="lg"
      overflow="hidden"
    >
      <Flex
        width="100%"
        height={{ base: "60px", md: "90px" }}
        background="#44bcd8"
        justifyContent="center"
        borderTopRadius="lg"
        boxShadow="md"
        pt={5}
        alignItems="center"
      >
        <Text
          fontSize={{ base: "xl", md: "3xl" }}
          fontWeight="bold"
          marginBottom="1rem"
          textAlign="center"
          textColor="white"
        >
          {title}
        </Text>
      </Flex>
      <Flex flexWrap="wrap" justifyContent="space-between" p="1rem">
        {images.map((image, index) => (
          <Image
            key={index}
            src={image}
            alt={`Category ${title} Image ${index + 1}`}
            maxW={{ base: "100%", md: "45%" }}
            height="auto"
            borderRadius="lg"
            boxShadow="lg"
            borderColor="#99eaff"
            mt={4}
          />
        ))}
      </Flex>
      <Center>
        <Center>
          <Link to="/products" state={buttonState}>
            <Button
              bgColor="white"
              boxShadow="sm"
              textColor="#006078"
              mt="1rem"
              minWidth={{ base: "250px", md: "350px" }}
            >
              View More
            </Button>
          </Link>
        </Center>
      </Center>
    </Box>
  );
};

const CircularComponent = ({ img, title }) => {
  const circleStyle = {
    width: "180px",
    height: "180px",
    borderRadius: "50%",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    margin: "1rem",
    overflow: "hidden",
  };
  return (
    <Box style={circleStyle} className="logo hidden">
      <Flex direction="column" alignItems="center">
        <Image src={img} alt={title} maxW="50px" />
        <Text
          mx={4}
          fontSize={{
            base: "sm",
            md: "lg",
          }}
          textAlign="center"
          fontWeight="bold"
          mt={{
            base: "0.25rem",
            md: "1rem",
          }}
        >
          {title}
        </Text>
      </Flex>
    </Box>
  );
};

export default Home;
