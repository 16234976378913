import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar.js";
import AboutUs from "./pages/AboutUs.js";
import Contact from "./pages/Contact.js";
import Events from "./pages/Events";
import Home from "./pages/Home.js";
import Infrastructure from "./pages/Infrastructure.js";
import ProductGallery from "./pages/ProductGallery";
import ProductManagement from "./pages/ProductManagement";
import WhyUs from "./pages/WhyUs.js";
import WhatsappChat from "./components/WhatsappChat";

function App() {
  return (
    <ChakraProvider>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          {/* <Route path="/construction" element={<Construction />} /> */}
          <Route path="/about" element={<AboutUs />} />
          <Route path="/whyus" element={<WhyUs />} />
          <Route path="/infrastructure" element={<Infrastructure />} />
          <Route path="/product-development" element={<ProductManagement />} />
          <Route path="/events" element={<Events />} />
          <Route path="/products" element={<ProductGallery />} />
        </Routes>
        <WhatsappChat />
        <Footer />
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
