import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import "../assets/css/WhatsappChat.css";

const WhatsappChat = () => {
  return (
    <a
      href="https://wa.me/+918452032291"
      className="chat-button"
      target="_blank"
      rel="noopener noreferrer"
    >
      <FaWhatsapp className="chat-icon" />
      <span className="chat-text">Chat</span>
    </a>
  );
};

export default WhatsappChat;
