import { Box, Flex, Heading } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import "../assets/css/ProductManagement.css";
import HorizontalCard from "../components/HorizontalCard";

const ProductManagement = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        } else {
          entry.target.classList.remove("show");
        }
      });
    });
    const hiddenElements = document.querySelectorAll(".hidden");
    hiddenElements.forEach((el) => observer.observe(el));

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollY]);

  return (
    <>
      <Flex
        flexDir={{ base: "column", md: "column" }}
        justifyContent="space-evenly"
        alignItems="center"
        minHeight="calc(92vh - 110px)"
        bgGradient="linear(-50deg, white 50%, #3bd6d3 50%)"
        // bgPosition="center"
        color="#333"
        fontFamily="Montserrat"
        p={4}
        boxShadow="lg"
        minH="calc(100vh - 184px)"
      >
        <Box
          // flex="35%"
          display="flex"
          flexDir="column"
          // h="100vh"
          justifyContent="center" // Center horizontally
          alignItems="center" // Center vertically
          // p={4}
          lineHeight="1.7"
          mt={2}
        >
          <Heading
            as="h2"
            alignItems="center"
            size={{ base: "xl", md: "xl", lg: "2xl" }}
          >
            Sankalp Diamond's NPI Process
          </Heading>
        </Box>

        <Flex
          // bgColor="yellow"
          w="100%"
          // minH="100vh"
          // flex="65%"
          flexDir={{ base: "column", md: "row" }} // Column format for HorizontalCard components
          p={4}
          alignItems="center"
        >
          <Flex flexDir="column" p={4}>
            <HorizontalCard
              title="Pro Active process, where Resources are Allocated"
              description="NPI involves the strategic allocation of resources. We carefully assign our design talent, source the right materials, and optimize production capacity to create our jewellery pieces efficiently."
            />

            <HorizontalCard
              title="Time to Market"
              description="Time is critical in the jewellery industry. Our NPI process is designed to minimize the time it takes for our innovative jewellery designs to go from concept to being available to our customers."
              mt={4}
            />

            <HorizontalCard
              title="First Mover Advantage"
              description="We aim to be pioneers in the industry. NPI ensures that we are the first to introduce unique jewellery designs, giving us a competitive edge."
              mt={4}
            />

            <HorizontalCard
              title="On going Process"
              description="NPI is not a one-time event; it's a continuous journey. We constantly refine existing designs, explore new materials, and adapt to changing market trends."
              mt={4}
            />
          </Flex>
          <Flex flexDir="column" p={4}>
            <HorizontalCard
              title="Continuous Development"
              description="Our commitment to NPI means a relentless pursuit of excellence. We invest in research and development to create jewellery that exceeds our customers' expectations."
              mt={4}
            />

            <HorizontalCard
              title="More Flexible Approach"
              description="We are flexible and responsive to our customers' needs. NPI allows us to quickly adapt to emerging trends and tailor our offerings accordingly."
              mt={4}
            />

            <HorizontalCard
              title="Cross Functional Teams"
              description="Success in NPI is the result of collaboration across teams, including designers, artisans, marketers, and supply chain experts. Together, we ensure that every jewellery piece aligns with our brand and market strategy."
              mt={4}
            />

            <HorizontalCard
              title="From Initial Idea generation to Final Commercialization"
              description="NPI covers the entire spectrum, from initial idea to final commercialization. It involves meticulous planning, creativity, craftsmanship, and effective marketing and sales strategies."
              mt={4}
            />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default ProductManagement;
