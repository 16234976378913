import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Heading, Text } from "@chakra-ui/react";

const InfraCard = ({ imageUrls, title, description }) => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <Box
      my={4}
      // mr={{ base: 0, md: 8 }}
      h="470px"
      w="300px"
      borderWidth="1px"
      overflow="hidden"
      boxShadow="lg"
      borderRadius="md"
      transition="transform 0.2s"
      _hover={{ transform: "scale(1.05)" }}
    >
      <Slider {...sliderSettings}>
        {imageUrls.map((imageUrl, index) => (
          <div key={index}>
            <img
              src={imageUrl}
              alt={title}
              style={{
                height: "350px",
                width: "100%",
                objectFit: "cover",
                marginBottom: -5, // Remove default margin
                padding: 0,
              }}
            />
          </div>
        ))}
      </Slider>
      <Box bgColor="white" h="120px" p={4}>
        <Heading as="h2" size="md" mb={2}>
          {title}
        </Heading>
        <Text lineHeight="2" color="gray.600">
          {description}
        </Text>
      </Box>
    </Box>
  );
};

export default InfraCard;
