import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons"; // Import the ChevronRightIcon
import { Box, Button, Image, VStack, useMediaQuery } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import ring from "../assets/images/Products/FeRing.png";
import bracelet from "../assets/images/Products/bracelet.png";
import bangle from "../assets/images/Products/bangle.png";
import designer from "../assets/images/Products/designer.png";
import earrings from "../assets/images/Products/earrings.png";
import necklace from "../assets/images/Products/necklace.png";
import pendant from "../assets/images/Products/pendant.png";
import gentsRing from "../assets/images/Products/ring.png";
import tanmaniya from "../assets/images/Products/tanmaniya.png";

const Sidebar = ({
  scrollToSection,
  ringsSectionRef,
  earringsSectionRef,
  pendantSectionRef,
  braceletSectionRef,
  tanmaniyaSectionRef,
  necklaceSectionRef,
  gentRingSectionRef,
  designerSectionRef,
  banglesSectionRef,
}) => {
  const [isSmallerScreen] = useMediaQuery("(max-width: 768px)");
  const [isSidebarOpen, setIsSidebarOpen] = useState(!isSmallerScreen);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsSidebarOpen(false);
      } else {
        setIsSidebarOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const categories = [
    {
      name: "Rings",
      icon: (
        <Image
          src={ring}
          alt="Bracelets"
          w="24px"
          h="24px"
          mr={isSidebarOpen && "2"}
        />
      ),
    },
    {
      name: "Earrings",
      icon: (
        <Image
          src={earrings}
          alt="Bracelets"
          w="24px"
          h="24px"
          mr={isSidebarOpen && "2"}
        />
      ),
    },
    {
      name: "Pendants",
      icon: (
        <Image
          src={pendant}
          alt="Bracelets"
          w="24px"
          h="24px"
          mr={isSidebarOpen && "2"}
        />
      ),
    },
    {
      name: "Bracelets",
      icon: (
        <Image
          src={bracelet}
          alt="Bracelets"
          w="24px"
          h="24px"
          mr={isSidebarOpen && "2"}
        />
      ),
    },
    {
      name: "Bangles",
      icon: (
        <Image
          src={bangle}
          alt="Bangles"
          w="24px"
          h="24px"
          mr={isSidebarOpen && "2"}
        />
      ),
    },
    {
      name: "Tanmaniya",
      icon: (
        <Image
          src={tanmaniya}
          alt="Bracelets"
          w="24px"
          h="24px"
          mr={isSidebarOpen && "2"}
        />
      ),
    },
    {
      name: "Necklace",
      icon: (
        <Image
          src={necklace}
          alt="Bracelets"
          w="24px"
          h="24px"
          mr={isSidebarOpen && "2"}
        />
      ),
    },
    {
      name: "Gent's Rings",
      icon: (
        <Image
          src={gentsRing}
          alt="Bracelets"
          w="24px"
          h="24px"
          mr={isSidebarOpen && "2"}
        />
      ),
    },
    {
      name: "Designer Products",
      icon: (
        <Image
          src={designer}
          alt="Bracelets"
          w="24px"
          h="24px"
          mr={isSidebarOpen && "2"}
        />
      ),
    },
  ];

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <Box>
      <Box
        display="flex"
        flexDir="column"
        width={isSidebarOpen ? "250px" : "80px"}
        bg="white"
        h="calc(100vh - 112px)"
        py={4}
        px={isSidebarOpen ? 4 : 2}
        boxShadow="md"
        position="fixed"
        top="112px"
        left="0"
        bottom="0"
        overflowY="auto"
        transition="width 0.3s"
      >
        <Button
          onClick={toggleSidebar}
          w="100%"
          h="40px"
          bg="#33d6ff"
          color="white"
          _hover={{
            bg: "white",
            color: "#33d6ff",
          }}
          mb={{ base: 8, md: 16 }}
        >
          {isSidebarOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </Button>
        <VStack align="start" spacing={{ base: 5, md: 6 }}>
          <Button
            variant="ghost"
            w="100%"
            _hover={{
              bg: "#33d6ff",
              color: "white",
            }}
            onClick={() => scrollToSection(ringsSectionRef)}
          >
            {categories[0].icon} {isSidebarOpen && categories[0].name}
          </Button>
          <Button
            variant="ghost"
            w="100%"
            _hover={{
              bg: "#33d6ff",
              color: "white",
            }}
            onClick={() => scrollToSection(earringsSectionRef)}
          >
            {categories[1].icon} {isSidebarOpen && categories[1].name}
          </Button>
          <Button
            variant="ghost"
            w="100%"
            _hover={{
              bg: "#33d6ff",
              color: "white",
            }}
            onClick={() => scrollToSection(pendantSectionRef)}
          >
            {categories[2].icon} {isSidebarOpen && categories[2].name}
          </Button>
          <Button
            variant="ghost"
            w="100%"
            _hover={{
              bg: "#33d6ff",
              color: "white",
            }}
            onClick={() => scrollToSection(braceletSectionRef)}
          >
            {categories[3].icon} {isSidebarOpen && categories[3].name}
          </Button>
          <Button
            variant="ghost"
            w="100%"
            _hover={{
              bg: "#33d6ff",
              color: "white",
            }}
            onClick={() => scrollToSection(banglesSectionRef)}
          >
            {categories[4].icon} {isSidebarOpen && categories[4].name}
          </Button>
          <Button
            variant="ghost"
            w="100%"
            _hover={{
              bg: "#33d6ff",
              color: "white",
            }}
            onClick={() => scrollToSection(tanmaniyaSectionRef)}
          >
            {categories[5].icon} {isSidebarOpen && categories[5].name}
          </Button>
          <Button
            variant="ghost"
            w="100%"
            _hover={{
              bg: "#33d6ff",
              color: "white",
            }}
            onClick={() => scrollToSection(necklaceSectionRef)}
          >
            {categories[6].icon} {isSidebarOpen && categories[6].name}
          </Button>
          <Button
            variant="ghost"
            w="100%"
            _hover={{
              bg: "#33d6ff",
              color: "white",
            }}
            onClick={() => scrollToSection(gentRingSectionRef)}
          >
            {categories[7].icon} {isSidebarOpen && categories[7].name}
          </Button>
          <Button
            variant="ghost"
            w="100%"
            _hover={{
              bg: "#33d6ff",
              color: "white",
            }}
            onClick={() => scrollToSection(designerSectionRef)}
          >
            {categories[8].icon} {isSidebarOpen && categories[8].name}
          </Button>
        </VStack>
      </Box>
      <Box width={isSidebarOpen ? "250px" : "80px"} bgColor="transparent"></Box>
    </Box>
  );
};

export default Sidebar;
