import React from "react";
import {
  Box,
  Flex,
  Button,
  IconButton,
  HStack,
  Image,
  useMediaQuery,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import "../assets/css/Navbar.css";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/images/SKP Full logo.png";
import skpNew from "../assets/images/SKP_new.png";
const Navbar = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const location = useLocation();

  const toggleNav = () => {
    setIsOpen(!isOpen);
  };
  window.scrollTo(0, 0);

  const [isScreenSmallerThan1340] = useMediaQuery("(max-width: 1240px)");

  return (
    <Box boxShadow="lg" bg="#00FFFF">
      <Flex
        as="nav"
        align="center"
        justify="space-between"
        wrap="wrap"
        py={4}
        px={8}
        bg="white"
        color="#006078"
        // minH="10vh"
        position="fixed"
        zIndex="2"
        w="100vw"
        boxShadow="lg"
        // maxH="12vh"
        h="112px"
        transition="height 0.3s ease-in-out"
      >
        <Link to="/">
          <Image
            src={skpNew}
            alt="Your Logo"
            display="block"
            // ml={{ base: "20%", md: 20 }}
            w="200px"
            // aspectRatio="3/2"
            // objectFit="contain"
            // mr="20px"
            // border="1px solid black"
            // backgroundColor="black"
          />
        </Link>

        {/* <h1
          className="heading-container"
          style={{
            fontWeight: "bold",
            letterSpacing: "0.5rem",
            fontFamily: "Montserrat",
          }}
        >
          SANKALP DIAMOND
        </h1> */}

        <HStack spacing={6} display={isScreenSmallerThan1340 ? "none" : "flex"}>
          <Link to="/about">
            <Button
              variant="ghost"
              textColor={location.pathname === "/about" ? "white" : "#006078"}
              _hover={{
                bg: "#006078",
                color: "white",
              }}
              bg={location.pathname === "/about" ? "#006078" : "white"}
            >
              About Us
            </Button>
          </Link>
          <Link to="/whyus">
            <Button
              variant="ghost"
              textColor={location.pathname === "/whyus" ? "white" : "#006078"}
              _hover={{
                bg: "#006078",
                color: "white",
              }}
              bg={location.pathname === "/whyus" ? "#006078" : "white"}
            >
              Why Us
            </Button>
          </Link>

          <div className="button-container">
            <Link to="/products">
              <Button
                variant="ghost"
                textColor={
                  location.pathname === "/products" ? "white" : "#006078"
                }
                _hover={{
                  bg: "#006078",
                  color: "white",
                }}
                bg={location.pathname === "/products" ? "#006078" : "white"}
              >
                Product Gallery
              </Button>
            </Link>
            <div className="dropdown">
              <Link to="/products" state="rings-section">
                Rings
              </Link>
              <Link to="/products" state="earrings-section">
                Earrings
              </Link>
              <Link to="/products" state="pendant-section">
                Pendant Sets
              </Link>
              <Link to="/products" state="bracelet-section">
                Bracelets
              </Link>
              <Link to="/products" state="bangles-section">
                Bangles
              </Link>
              <Link to="/products" state="tanmaniya-section">
                Tanmaniya
              </Link>
              <Link to="/products" state="necklace-section">
                Necklace Sets
              </Link>
              <Link to="/products" state="gentRing-section">
                Gent's Rings
              </Link>
              <Link to="/products" state="designer-section">
                Designer Products
              </Link>
            </div>
          </div>

          <Link to="/infrastructure">
            <Button
              variant="ghost"
              textColor={
                location.pathname === "/infrastructure" ? "white" : "#006078"
              }
              _hover={{
                bg: "#006078",
                color: "white",
              }}
              bg={location.pathname === "/infrastructure" ? "#006078" : "white"}
            >
              Infrastructure
            </Button>
          </Link>
          <Link to="/events">
            <Button
              variant="ghost"
              textColor={location.pathname === "/events" ? "white" : "#006078"}
              _hover={{
                bg: "#006078",
                color: "white",
              }}
              bg={location.pathname === "/events" ? "#006078" : "white"}
            >
              Events
            </Button>
          </Link>
          <Link to="/product-development">
            <Button
              variant="ghost"
              textColor={
                location.pathname === "/product-development"
                  ? "white"
                  : "#006078"
              }
              _hover={{
                bg: "#006078",
                color: "white",
              }}
              bg={
                location.pathname === "/product-development"
                  ? "#006078"
                  : "white"
              }
            >
              Product Development
            </Button>
          </Link>
          <Link to="/contact">
            <Button
              variant="ghost"
              textColor={location.pathname === "/contact" ? "white" : "#006078"}
              _hover={{
                bg: "#006078",
                color: "white",
              }}
              bg={location.pathname === "/contact" ? "#006078" : "white"}
            >
              Contact
            </Button>
          </Link>
        </HStack>

        <IconButton
          aria-label="Open/Close navigation menu"
          display={isScreenSmallerThan1340 ? "block" : "none"}
          onClick={toggleNav}
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
        />
      </Flex>

      <div className={`mobile-menu ${isOpen ? "active" : ""}`}>
        <Box h="113px"></Box>
        <Box
          p={4}
          pb={8}
          bg="white"
          color="#f8f8f8"
          display={isScreenSmallerThan1340 ? "block" : "none"}
          position="fixed"
          zIndex="4"
          top="112px"
          left="0"
          right="0"
          transformOrigin="top"
        >
          <Link to="/" onClick={toggleNav}>
            <NavButton name="Home" path="" />
          </Link>

          <Link to="/about" onClick={toggleNav}>
            <NavButton name="About Us" path="about" />
          </Link>
          <Link to="/whyus" onClick={toggleNav}>
            <NavButton name="Why Us" path="whyus" />
          </Link>
          <Link to="/products" onClick={toggleNav}>
            <NavButton name="Product Gallery" path="products" />
          </Link>
          <Link to="/infrastructure" onClick={toggleNav}>
            <NavButton name=" Infrastructure" path="infrastructure" />
          </Link>
          <Link to="/events" onClick={toggleNav}>
            <NavButton name="Events" path="events" />
          </Link>
          <Link to="/product-development" onClick={toggleNav}>
            <NavButton name="Product Development" path="product-development" />
          </Link>
          <Link to="/contact" onClick={toggleNav}>
            <NavButton name="Contact" path="contact" />
          </Link>
        </Box>
      </div>
      <Box h="112px" display={isOpen && "none"} bgColor="transparent"></Box>
    </Box>
  );
};

const NavButton = ({ name, path }) => {
  const location = useLocation();
  return (
    <Button
      variant="ghost"
      w="100%"
      mb={4}
      textColor={location.pathname === `/${path}` ? "white" : "#006078"}
      _hover={{
        bg: "#006078",
        color: "white",
      }}
      bg={location.pathname === `/${path}` ? "#006078" : "white"}
    >
      {name}
    </Button>
  );
};

export default Navbar;
